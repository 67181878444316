<template>
  <section class="home-block">
    <div class="container">
      <div class="content">
        <h2 class="h3" ref="h2" v-if="content.h2" v-html="content.h2"></h2>
        <div class="row">
          <div class="col-lg-4">
            <div
              v-if="content.subtitle"
              ref="subtitle"
              class="subtitle h5"
              v-html="content.subtitle"
            />
          </div>
          <div class="col-lg-8">
            <div
              v-if="content.text"
              ref="text"
              class="text"
              v-html="content.text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 offset-lg-4">
            <div
              v-if="content.title"
              ref="title"
              class="title h1"
              v-html="content.title"
            />
            <div ref="video" class="video" v-if="content.video">
              <video @click="stopVideo" ref="videoTermo" :poster="content.video.poster || ''" class="video__source" >
                <source type="video/mp4" :src="content.video.sourceMob" media="(max-width: 768px)" />
                <source type="video/mp4" :src="content.video.sourceDesk" />
              </video>
              <div v-if="videoIcon" class="video__play" @click="playVideo"></div>
              <div
                v-if="content.video.volume"
                class="video__volume"
                @click="setVolume"
              >
                <svg
                  v-if="muted"
                  role="button"
                  aria-label="Включить звук"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.8018 1.64679L20.6243 2.21546L6.57983 22.5306L5.75726 21.9619L19.8018 1.64679Z"
                    fill="white"
                  />
                  <path
                    d="M14.7037 6.6782V3.8446L8.64434 7.72857H2.58496V16.273H8.07055L8.76188 15.273H3.58496V8.72857H8.93732L13.7037 5.67339V8.12468L14.7037 6.6782Z"
                    fill="white"
                  />
                  <path
                    d="M11.9023 17.1734L13.7037 18.3281V14.5677L14.7037 13.1212V20.1569L11.3333 17.9965L11.9023 17.1734Z"
                    fill="white"
                  />
                  <path
                    d="M19.8189 6.24174C21.2133 7.81125 21.9909 9.83345 22.0071 11.9329C22.0234 14.0322 21.2773 16.0661 19.9076 17.6569L19.9053 17.6596L20.884 18.503C21.1029 18.249 21.308 17.9852 21.4987 17.7127C22.6811 16.0235 23.3152 14.0011 23.2991 11.9229C23.2831 9.84467 22.6178 7.83233 21.4093 6.16169C21.2152 5.89322 21.0069 5.63358 20.7851 5.3839L20.7825 5.38093L19.8169 6.23942L19.8189 6.24174Z"
                    fill="white"
                  />
                  <path
                    d="M20.6238 11.9955C20.6228 10.8657 20.235 9.75265 19.4935 8.75098L18.348 9.17238C18.5563 9.45368 18.7325 9.74508 18.8756 10.0438C19.1762 10.6708 19.3312 11.3301 19.3318 11.9961C19.3324 12.6621 19.1786 13.3214 18.8793 13.9486C18.7367 14.2475 18.561 14.5392 18.3531 14.8208L19.4994 15.2411C20.2391 14.2388 20.6249 13.1254 20.6238 11.9955Z"
                    fill="white"
                  />
                </svg>
                <svg
                  v-else
                  role="button"
                  aria-label="Выключить звук"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.64434 7.72857H2.58496V16.273H8.64434L14.7037 20.1569V3.8446L8.64434 7.72857ZM13.7037 5.67339L8.93732 8.72857H3.58496V15.273H8.93732L13.7037 18.3281V5.67339Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.8194 6.24174C21.2138 7.81125 21.9914 9.83345 22.0076 11.9329C22.0239 14.0322 21.2778 16.0661 19.9081 17.6569C19.9073 17.6578 19.9065 17.6587 19.9058 17.6596L20.8845 18.503C21.1034 18.249 21.3085 17.9852 21.4992 17.7127C22.6816 16.0235 23.3157 14.0011 23.2996 11.9229C23.2835 9.84467 22.6182 7.83233 21.4098 6.16169C21.2156 5.89322 21.0074 5.63358 20.7856 5.3839C20.7848 5.38291 20.7839 5.38192 20.783 5.38093L19.8174 6.23942C19.8181 6.24019 19.8188 6.24096 19.8194 6.24174Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.4941 8.75098C20.2357 9.75265 20.6234 10.8657 20.6245 11.9955C20.6255 13.1254 20.2397 14.2388 19.5 15.2411L18.3538 14.8208C18.5616 14.5392 18.7373 14.2475 18.8799 13.9486C19.1792 13.3214 19.333 12.6621 19.3324 11.9961C19.3318 11.3301 19.1768 10.6708 18.8763 10.0438C18.7331 9.74507 18.5569 9.45368 18.3486 9.17238L19.4941 8.75098Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="content.list" class="list">
        <div ref="list" class="row">
          <div
            v-for="item in content.list"
            :key="item.title"
            class="col-lg-4 col-md-6"
          >
            <SlidesItem :item="item" />
            <!-- <div class="list__item">
							<div class="h4 list__title" v-if="item.title" v-html="item.title"></div>
							<div class="list__text" v-if="item.text" v-html="item.text"></div>
						</div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesItem from "@/components/SlidesItem.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesItem,
  },
  props: {
    content: {
      type: Object,
    },
  },
  data(){
    return {
      videoIcon: true,
      muted: true,
    }
  },
  mounted() {
    if (!is_touch()) {
      let elements = [this.$refs.list];
      if (this.$refs.title) {
        elements = [this.$refs.title, ...elements];
      }
      if (this.$refs.video) {
        elements = [this.$refs.video, ...elements];
      }
      if (this.$refs.text) {
        elements = [this.$refs.text, ...elements];
      }
      if (this.$refs.subtitle) {
        elements = [this.$refs.subtitle, ...elements];
      }
      if (this.$refs.h2) {
        elements = [this.$refs.h2, ...elements];
      }
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(gsap.utils.toArray(elements), {
          y: 150,
          stagger: { each: 0.07 },
        });
    }
  },
  methods:{
    playVideo() {
      this.$refs.videoTermo.play();
      this.videoIcon = false;
    },
    stopVideo() {
      this.$refs.videoTermo.pause();
      this.videoIcon = true;
    },
    setVolume() {
      this.muted = !this.muted;
      this.$refs.videoTermo.muted = this.muted;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
}

h2 {
  text-align: center;
  margin-bottom: 80px;
}

.subtitle {
  max-width: 220px;
  margin: 0;
}

.text {
  max-width: 700px;
  margin-bottom: 80px;
  font-size: 20px;
}

.title {
  margin: 0;
}
/* .list__title {
		margin: 0px 0px 15px 0px;
		letter-spacing: -1px;
	}
	.list__item {
		max-width: 335px;
	} */
.list {
  margin-bottom: -24px;
}

.list :deep(.item) {
  max-width: none;
  margin-bottom: 24px;
}

.video {
  position: relative;
  display: table;
  margin-top: 80px;
  max-width: 704px;
  width: 100%;
}

.video__source {
  max-width: 704px;
  width: 100%;
}

.video__play {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
  background: url(~@i/video-play.svg);
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.video__play:hover {
  transform: scale(1.5);
  transition: transform 1s;
}

.video__volume {
  position: absolute;
  right: 10px;
  bottom: 20px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .list {
    margin-bottom: -40px;
  }

  .list :deep(.item) {
    max-width: 335px;
    margin-bottom: 40px;
    color: inherit;
  }

  .list :deep(.item__title) {
    margin: 0 0 15px;
    font-size: 36px;
    letter-spacing: -1px;
  }

  .list :deep(.item__image) {
    display: none;
  }

  .list :deep(.item__content) {
    min-height: auto;
    padding: 0;
  }
}
@media (max-width: 991px) {
  .video {
    margin-top: 0;
  }
  .video__play {
    width: 48px;
    height: 48px;
    background-size: 48px 48px;
    margin-left: -24px;
    margin-top: -24px;
  }
  .content {
    margin-bottom: 0;
  }

  .content > div:first-child {
    order: 2;
  }

  .content > div:last-child {
    order: 1;
  }

  h2 {
    margin-bottom: 57px;
  }

  .text {
    margin-bottom: 57px;
  }

  .title {
    margin-bottom: 27px;
  }

  .subtitle {
    margin-bottom: 23px;
  }
}
</style>
