<template>
  <section class="home-block pt-0">
    <div class="container">
      <div
        v-if="content.title"
        ref="title"
        class="h2 title"
        v-html="content.title"
      />
      <div v-if="content.img" ref="item" class="item">
        <div class="image__wrap">
          <div ref="image" class="image">
            <img
              loading="lazy"
              width="1332"
              height="820"
              :src="content.img"
              :alt="content.title"
            />
          </div>
        </div>
        <div
          v-if="content.text"
          ref="text"
          class="text"
          v-html="content.text"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  props: {
    content: {
      type: Object,
    },
  },
  mounted: function () {
    if (!is_touch()) {
      gsap.fromTo(
        this.$refs.image,
        { y: "-40%" },
        {
          y: "40%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: 1,
          },
          ease: "none",
        }
      );
      gsap.from(gsap.utils.toArray([this.$refs.title, this.$refs.item]), {
        y: 150,
        stagger: { each: 0.1 },
        scrollTrigger: {
          trigger: this.$el,
          start: "top bottom",
          end: "13% center",
          scrub: 1.5,
        },
      });
      gsap.from(this.$refs.text, {
        y: -250,
        stagger: { each: 0.1 },
        scrollTrigger: {
          trigger: this.$el,
          //start: 'top bottom',
          end: "70% top",
          scrub: 1.5,
        },
      });
    }
  },
};
</script>

<style scoped>
.title {
  max-width: 800px;
  margin-bottom: 35px;
}

.item {
  position: relative;
  margin-right: -105px;
}

.image__wrap {
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
}

.image {
  position: absolute;
  top: 0%;
  right: 0;
  bottom: 0%;
  left: 0;
}

.image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .text {
    position: absolute;
    right: 105px;
    bottom: -120px;
    max-width: 700px;
    padding: 55px;
    background: #fff;
  }
}
@media (max-width: 1279px) {
  .item {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .text {
    right: 30px;
    max-width: 630px;
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .text {
    max-width: 100%;
    margin-top: 23px;
    padding: 0;
  }

  .image__wrap {
    padding-bottom: 70%;
  }

  .title {
    margin-bottom: 23px;
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .home-block {
    padding-bottom: 0;
  }

  .item {
    margin-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 36px;
  }
}
</style>
