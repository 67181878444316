<template>
  <section class="home-block">
    <div class="content">
      <div class="container">
        <div ref="subtitle" class="h5 subtitle">Преимущества</div>
        <div ref="title" class="h2 title">
          Узнайте о&nbsp;других преимуществах
        </div>
        <div ref="text" class="text">
          <p>
            Продуманность в&nbsp;каждой детали, технологичные сервисы
            и&nbsp;уникальные локации. Ознакомьтесь с&nbsp;преимуществами вашего
            дома.
          </p>
        </div>
        <div ref="list" class="list">
          <swiper
            class="hasPagination"
            navigation
            :pagination="{ clickable: true }"
            :preload-images="false"
            :slides-per-view="'auto'"
            :space-between="24"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="item in preparedItems" :key="item.title">
              <SlidesItem :item="item" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesItem from "@/components/SlidesItem.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Swiper,
    SwiperSlide,
    SlidesItem,
  },
  inject: ['mainStore'],
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    items() {
      return this.mainStore.state.data?.benefit_project.slides ?? [];
    },
    preparedItems() {
      return this.items.filter(({ url }) => !url || !this.$route.path.includes(url.replace(/\/$/, ''))).map((item) => {
        return {
          img: item.preview.source,
          title: item.name,
          text: item.description,
          link: item.url
        };
      });
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      if (!is_touch()) {
        this.$nextTick(() => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: this.$el,
                start: "top bottom",
                end: "40% center",
                scrub: 1.5,
              },
            })
            .from(
              gsap.utils.toArray([
                this.$refs.subtitle,
                this.$refs.title,
                this.$refs.text,
                this.$refs.list,
              ]),
              {
                y: 100,
                stagger: { each: 0.1 },
              },
              0
            );
          gsap.from(this.swiper.$el[0].querySelectorAll("img.img-to-animate"), {
            scale: 1.3,
            scrollTrigger: {
              trigger: this.swiper.$el[0],
              start: "top bottom",
              end: "40% center",
              scrub: 1.5,
            },
            stagger: { each: 0.05, ease: "none" },
          });
          gsap.from(this.swiper.$el[0].querySelectorAll(".item"), {
            x: 200,
            scrollTrigger: {
              trigger: this.swiper.$el[0],
              //start: 'top bottom',
              //end: 'bottom top',
              toggleActions: "play none none reverse",
            },
            stagger: { each: 0.05, ease: "none" },
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.subtitle {
  margin-bottom: 57px;
  text-align: center;
}

.title {
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}

.text {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.list {
  margin-top: 60px;
}

.list :deep(.swiper-slide) {
  width: 496px;
}

.list .swiper-container {
  overflow: inherit;
}
@media (max-width: 1279px) {
  .list :deep(.swiper-slide) {
    width: 450px;
  }
}
@media (max-width: 767px) {
  .subtitle {
    margin-bottom: 27px;
  }

  .title {
    margin-bottom: 27px;
  }

  .list {
    margin-top: 37px;
  }

  .list :deep(.swiper-slide) {
    width: 375px;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }
}
@media (max-width: 374px) {
  .title {
    font-size: 47px;
  }

  .list :deep(.swiper-slide) {
    width: 290px;
  }
}
</style>
